<template>
  <div>
    <a-drawer
      title="记账"
      :width="500"
      :visible="visible"
      placement="right"
      :closable="false"
      @close="onClose"
    >
      <a-tabs type="card" @change="changeTab">
        <a-tab-pane key="1" tab="支出">
          <a-spin :spinning="confirmLoading">
            <a-form-model
              :model="form"
              ref="ruleForm"
              layout="horizontal"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item ref="icon" prop="icon" label="类型">
                <a-radio-group v-model="form.icon">
                  <span v-for="icon in icons" :key="icon.id">
                    <a-space direction="vertical" size="6">
                      <a-radio-button :value="icon.id">
                        <a-icon :type="icon.icon.icon_name"></a-icon>
                      </a-radio-button>
                      <center>{{ icon.account_name }}</center>
                    </a-space>
                  </span>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item ref="remark" label="备注" prop="remark">
                <a-input v-model="form.remark"></a-input>
              </a-form-model-item>
              <a-form-model-item ref="money" label="金额" prop="money">
                <a-input-number
                  v-model="form.money"
                  :min="0"
                  :max="100000"
                  :step="0.01"
                />
              </a-form-model-item>
              <a-form-model-item ref="date" label="日期" prop="date">
                <a-date-picker
                  v-model="form.date"
                  format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  :default-value="$moment()"
                />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="submit">完成</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane key="2" tab="收入">
          <a-spin :spinning="confirmLoading">
            <a-form-model
              :model="form"
              ref="ruleForm"
              :rules="rules"
              layout="horizontal"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item ref="icon" prop="icon" label="类型">
                <a-radio-group v-model="form.icon">
                  <span v-for="icon in icons" :key="icon.id">
                    <a-space direction="vertical" size="6">
                      <a-radio-button :value="icon.id">
                        <a-icon :type="icon.icon.icon_name"></a-icon>
                      </a-radio-button>
                      <center>{{ icon.account_name }}</center>
                    </a-space>
                  </span>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item ref="remark" label="备注" prop="remark">
                <a-input v-model="form.remark"></a-input>
              </a-form-model-item>
              <a-form-model-item ref="money" label="金额" prop="money">
                <a-input-number
                  v-model="form.money"
                  :min="0"
                  :max="100000"
                  :step="0.01"
                />
              </a-form-model-item>
              <a-form-model-item ref="date" label="日期" prop="date">
                <a-date-picker
                  v-model="form.date"
                  format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  :default-value="$moment()"
                />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="submit">完成</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-spin>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
  </div>
</template>

<script>
import { getAccountIcon, addAccount } from "@/api/daily_life";
import moment from "moment";
export default {
  data() {
    let validateMoney = (rule, value, callback) => {
      if (typeof value !== "number") {
        callback(new Error("金额必须是一个数字"));
      }
      if (value <= 0 || value > 100000) {
        callback(new Error("金额数目必须在0-100000之间"));
      }
      callback();
    };
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        remark: "",
        icon: undefined,
        money: 0,
        date: this.$moment(),
      },
      rules: {
        icon: [{ required: true, message: "请选择一个类型", trigger: "blur" }],
        money: [{ validator: validateMoney, trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
      icons: [],
      confirmLoading: false,
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          let data = {
            create_time: this.form.date.format("YYYY-MM-DD"),
            number: this.form.money,
            remark: this.form.remark,
            account_icon: this.form.icon,
          };
          addAccount(data)
            .then((res) => {
              if (res.data.code === 500) {
                this.$message.error(res.data.result);
              } else {
                this.$message.success(res.data.result);
                let start_time =
                  this.$parent.$parent.start_time.format("YYYY-MM-DD");
                let end_time =
                  this.$parent.$parent.end_time.format("YYYY-MM-DD");
                this.$parent.$parent.fetch(
                  {
                    page: 1,
                    page_size: 10,
                    start_time: start_time,
                    end_time: end_time,
                  },
                  true
                );
                this.visible = false;
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
          this.confirmLoading = false;
        }
      });
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    fetch(query) {
      this.confirmLoading = true;
      getAccountIcon(query)
        .then((res) => {
          this.icons = res.data.results;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.confirmLoading = false;
    },
    changeTab(key) {
      if (key === "1") {
        this.fetch({ page_size: 100, page: 1, icon_type: 0 });
      } else {
        this.fetch({ page_size: 100, page: 1, icon_type: 1 });
      }
      this.$refs["ruleForm"].resetFields(); // 表单重置
    },
    onClose() {
      this.visible = false;
    },
    init() {
      this.visible = true;
      this.fetch({ page_size: 100, page: 1, icon_type: 0 });
      console.log(this.icons);
    },
  },
};
</script>
