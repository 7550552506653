<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-button type="primary" icon="plus" @click="$refs.accountTypeDraw.init()"
      >记账</a-button
    >
    <a-button
      type="primary"
      icon="plus"
      style="margin-left: 10px"
      @click="$refs.catagorySetting.init()"
      >类别设置</a-button
    >
    <a-button
      type="primary"
      icon="plus"
      style="margin-left: 10px"
      @click="$refs.catagoryAdd.init()"
      >类别添加</a-button
    >
    <span style="margin-left: 10px; color: red">
      <span v-if="count.income !== 0"> 收入: {{ count.income }} </span>
      <span v-if="count.outcome !== 0"> 支出: {{ count.outcome }} </span>
    </span>
    <div style="margin-top: 20px">
      <span>
        开始时间:
        <a-date-picker
          v-model="start_time"
          format="YYYY-MM-DD"
          :disabled-date="disabledEndDate"
          :default-value="$moment()"
        >
        </a-date-picker>
      </span>
      <span>
        结束时间:
        <a-date-picker
          v-model="end_time"
          format="YYYY-MM-DD"
          :disabled-date="disabledEndDate"
          :default-value="$moment()"
        >
        </a-date-picker>
      </span>
      <a-button
        type="primary"
        style="margin-left: 10px"
        icon="search"
        @click="search()"
        >查询</a-button
      >
      <!-- :disabled-date="disabledDate" -->
    </div>
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px"
      size="small"
      :rowKey="(account) => account.id"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      bordered
    >
      <span slot="account_icon" slot-scope="text">
        <a-icon :type="text.icon_name"></a-icon>
      </span>
      <span slot="number" slot-scope="text, account">
        {{ changeNumber(text, account) }}
      </span>
    </a-table>
    <account-type-draw ref="accountTypeDraw" />
    <catagory-setting ref="catagorySetting" />
    <catagory-add ref="catagoryAdd" />
  </a-card>
</template>

<script>
import AccountTypeDraw from "./modules/AccountTypeDraw";
import CatagorySetting from "./modules/CatagorySetting";
import CatagoryAdd from "./modules/CatagoryAdd";
import moment from "moment";
import { getAccount } from "@/api/daily_life";
// import moment from 'moment';
export default {
  components: {
    AccountTypeDraw,
    CatagorySetting,
    CatagoryAdd,
  },
  data() {
    return {
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        onShowSizeChange: (current, pageSize) => (this.pageSize = pageSize),
      },
      count: {
        income: 0,
        outcome: 0,
      },
      loading: false,
      data: [],
      columns: [
        {
          title: "图标",
          dataIndex: "account_icon.icon",
          ellipsis: true,
          scopedSlots: { customRender: "account_icon" },
          width: "20%",
        },
        {
          title: "类型",
          dataIndex: "account_icon.account_name",
          ellipsis: true,
          scopedSlots: { customRender: "account_icon_type" },
          width: "20%",
        },
        {
          title: "时间",
          dataIndex: "create_time",
          ellipsis: true,
          scopedSlots: { customRender: "create_time" },
          width: "20%",
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
          scopedSlots: { customRender: "remark" },
          width: "20%",
        },
        {
          title: "金额",
          dataIndex: "number",
          ellipsis: true,
          scopedSlots: { customRender: "number" },
          width: "20%",
        },
      ],
      start_time: moment(),
      end_time: moment(),
    };
  },
  methods: {
    disabledEndDate(current) {
      return current && current > moment().endOf("day");
    },
    search() {
      let start_time = this.start_time.format("YYYY-MM-DD");
      let end_time = this.end_time.format("YYYY-MM-DD");
      this.fetch(
        { page: 1, page_size: 10, start_time: start_time, end_time: end_time },
        true
      );
    },
    changeNumber(number, account) {
      var result = "";
      let numbers = number.split(".");
      let decimal = numbers[1];
      result += numbers[0];
      if (decimal.substring(1, 2) === "0") {
        if (decimal.substring(0, 1) !== "0") {
          result += "." + decimal.substring(0, 1);
        }
      } else {
        result += "." + decimal;
      }
      if (account != undefined) {
        if (account.account_icon.icon_type === 0) {
          result = "-" + result;
        }
      }
      return result;
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      let start_time = this.start_time.format("YYYY-MM-DD");
      let end_time = this.end_time.format("YYYY-MM-DD");
      this.fetch(
        {
          page: pagination.current,
          page_size: pagination.pageSize,
          start_time: start_time,
          end_time: end_time,
        },
        false
      );
    },
    countCome(results) {
      let income = 0;
      let outcome = 0;
      if (results.length > 0) {
        for (let index = 0; index < results.length; index++) {
          let result = results[index];
          if (result.account_icon.icon_type === 0) {
            // 支出
            outcome = (outcome * 100 + parseFloat(result.number) * 100) / 100; // javaScript精度溢出
          } else {
            // 收入
            income = (income * 100 + parseFloat(result.number) * 100) / 100; // javaScript精度溢出
          }
        }
        this.count.income = income;
        this.count.outcome = outcome;
      }
    },
    fetch(query = {}, count = false) {
      this.loading = true;
      getAccount(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.loading = false;
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err.toString());
          this.loading = false;
        });
      query.page_size = 10000;
      if (count) {
        getAccount(query)
          .then((res) => {
            let result = res.data.results;
            this.countCome(result);
          })
          .catch((err) => {
            this.$message.error(err.toString());
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.fetch({ page: 1, page_size: 10 }, true);
  },
};
</script>
