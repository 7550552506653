<template>
  <div>
    <a-modal
      :visible="visible"
      title="类别设置"
      :width="700"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
    >
      <a-tabs type="card" @change="changeTab">
        <a-tab-pane key="1" tab="支出">
          <a-table
            style="width: 100%; margin-top: 10px"
            size="small"
            :loading="confirmLoading"
            :data-source="data"
            :columns="columns"
            showPagination="auto"
            :pagination="pagination"
            :rowKey="(account) => account.id"
            @change="handleTableChange"
            bordered
          >
            <span slot-scope="text" slot="icon">
              <a-icon :type="text.icon_name"></a-icon>
            </span>
            <span slot-scope="text" slot="action">
              <a-button
                type="danger"
                size="small"
                @click="deleteAccount(text.id)"
                >删除</a-button
              >
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="收入">
          <a-table
            style="width: 100%; margin-top: 10px"
            size="small"
            :loading="confirmLoading"
            :data-source="data"
            :columns="columns"
            showPagination="auto"
            :pagination="pagination"
            :rowKey="(account) => account.id"
            @change="handleTableChange"
            bordered
          >
            <span slot-scope="text" slot="icon">
              <a-icon :type="text.icon_name"></a-icon>
            </span>
            <span slot-scope="text" slot="action">
              <a-button
                type="danger"
                size="small"
                @click="deleteAccount(text.id)"
                >删除</a-button
              >
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import { getAccountIcon, DeleteAccountIcon } from "@/api/daily_life";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      current_page_size: 10,
      data: [],
      columns: [
        {
          title: "类别名称",
          dataIndex: "account_name",
          ellipsis: true,
          width: 80,
        },
        {
          title: "类别",
          dataIndex: "icon",
          ellipsis: true,
          scopedSlots: { customRender: "icon" },
          width: 80,
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        onShowSizeChange: (current, pageSize) => (this.pageSize = pageSize),
      },
      key: 1,
    };
  },
  methods: {
    handleCancel() {
      this.visible = false;
    },
    changeTab(key) {
      if (key === "1") {
        this.key = 1;
        this.visible = true;
        let params = { page: 1, icon_type: 0 };
        this.fetch(params);
      } else {
        this.key = 2;
        this.visible = true;
        let params = { page: 1, icon_type: 1 };
        this.fetch(params);
      }
    },
    fetch(params = {}) {
      this.confirmLoading = true;
      getAccountIcon(params)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.confirmLoading = false;
    },
    init() {
      this.visible = true;
      if (this.key === 1) {
        let params = { page: 1, icon_type: 0 };
        this.fetch(params);
      } else {
        let params = { page: 1, icon_type: 1 };
        this.fetch(params);
      }
    },
    deleteAccount(id) {
      DeleteAccountIcon(id)
        .then((res) => {
          console.log(res);
          this.fetch({
            page_size: this.pagination.pageSize,
            page: this.pagination.current,
            icon_type: this.key === 1 ? 0 : 1,
          });
          this.$message.success("删除成功");
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.current_page_size = pagination.pageSize;
      this.fetch({
        page_size: pagination.pageSize,
        page: pagination.current,
        icon_type: this.key === 1 ? 0 : 1,
      });
    },
  },
};
</script>
