<template>
  <div>
    <a-drawer
      title="类别添加"
      :width="500"
      :visible="visible"
      placement="right"
      :closable="false"
      @close="onClose"
    >
      <a-spin :spinning="confirmLoading">
        <a-form-model
          :model="form"
          ref="ruleForm"
          layout="horizontal"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="icon" prop="icon" label="图标">
            <a-radio-group v-model="form.icon">
              <span v-for="icon in icons" :key="icon.id">
                <a-radio-button :value="icon.id">
                  <a-icon :type="icon.icon_name"></a-icon>
                </a-radio-button>
              </span>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            ref="account_name"
            prop="account_name"
            label="类别名称"
          >
            <a-input v-model="form.account_name"></a-input>
          </a-form-model-item>
          <a-form-model-item ref="icon_type" prop="icon_type" label="类型">
            <a-select v-model="form.icon_type" placeholder="请输入类型">
              <a-select-option value="0">支出</a-select-option>
              <a-select-option value="1">收入</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            ref="belong_user"
            prop="belong_user"
            label="归属"
            v-if="store.state.user.isSuperuser"
          >
            <a-select v-model="form.belong_user">
              <a-select-option value="public">公共</a-select-option>
              <a-select-option value="private">私人</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submit">完成</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-drawer>
  </div>
</template>

<script>
import store from "@/store";
import { getIcon, addAccountIcon } from "@/api/daily_life";
export default {
  data() {
    this.store = store;
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      confirmLoading: false,
      form: {
        account_name: "",
        icon_type: undefined,
        icon: undefined,
        belong_user: undefined,
      },
      rules: {
        icon: [{ required: true, message: "请选择一个类型", trigger: "blur" }],
        account_name: [
          { required: true, message: "必须输入类别名称", trigger: "blur" },
        ],
        icon_type: [
          { required: true, message: "必须输入类型", trigger: "change" },
        ],
      },
      icons: [],
    };
  },
  methods: {
    onClose() {
      this.visible = false;
    },
    init() {
      this.visible = true;
      this.confirmLoading = true;
      getIcon({ page: 1, page_size: 1000 })
        .then((res) => {
          this.icons = res.data.results;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.confirmLoading = false;
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          let data = this.form;
          if (data.belong_user === undefined) {
            data.belong_user = "private";
          }
          addAccountIcon(data)
            .then((res) => {
              if (res.data.code === "500") {
                this.$message.error(res.data.result);
              } else {
                this.$message.success(res.data.result);
                this.visible = false;
              }
              this.confirmLoading = false;
            })
            .catch((err) => {
              this.$message.error(err.toString());
              this.confirmLoading = false;
            });
        }
      });
    },
  },
};
</script>
